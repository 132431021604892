<template>
  <div class="recharge-page">
    <headerBar :title="i18n.t('withdraw.title')" class="red" :showArrow="true" @back="back"></headerBar>

    <div class="flex-center-between bank-title">
      <div>银行卡提现</div>
    </div>

    <div class="bank-box flex-center">
      <div class="name flex-column-center">
        <img :src="withdrawInfo.icon" alt="" class="big">
      </div>
      <div class="flex-1">
        <div class="info big">{{withdrawInfo.name}}</div>
        <div class="info gray">充值3次,提现2次</div>
        <div></div>
      </div>
      <div class="transfer-icon" @click="toTransfer">
        更换
      </div>
    </div>

    <div class="recharge-form ">
      <div class="label">{{i18n.t('withdraw.label4')}}</div>
      <div class="input">
        <input type="number" v-model="form.number" :placeholder="i18n.t('withdraw.placeholder1')">
      </div>
    </div>


    <div class="btn mt-50">
      <Button @click="submit">立即提现</Button>
    </div>


    <Popup v-model="showPopup" class="modal-popup">
      <div class="bank-list">
        <div class="modal-title flex-center-between">
          <span>USDT管理</span>
          <img :src="closeUrl" alt="" @click="cancel">
        </div>

        <div class="list">
          <div class="bank flex-center" v-for="(item, i) in bitList" :key="i"  @click="chooseBit(item)">
            <div class="name flex-column-center">
              <img :src="item.icon" alt="">
            </div>
            <div class="flex-1">
              <div class="info big">{{item.name}}</div>
              <div class="info gray">充值4ci,提现2次</div>
              <div></div>
            </div>
            <div class="icon">
              <img :src="checkUrl2" alt="" v-if="chooseBitInfo.key == item.key">
              <img :src="checkUrl" alt="" v-else>
            </div>
          </div>

          <div class="bank flex-center">
            <img :src="addUrl" alt="" class="add-icon">
            <span class="desc">添加USDT</span>
          </div>
        </div>

        <div class="btn">
          <Button class="" @click="cancel">取消</Button>
          <Button class="blue" @click="doTransfer">确定</Button>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { Button, Popup } from 'vant'
import headerBar from '@/components/header'
import Clipboard from 'clipboard';
import { rechargeStatistics, doRecharge, userInfo,ListPayManage,getCompanyBank } from '@/api/user'
export default {
  components: {
    headerBar,
    Button,
    Popup
  },
  data() {
    return {
      form: {
        number: '',
        img: '',
      },
      bitUrl: '',
      showPopup: false,
      transferUrl: require('@/assets/imgs/icon-transfer.png'),
      bankUrl: require('@/assets/imgs/icon-card.png'),
      copyUrl: require('@/assets/imgs/icon-copy.png'),
      pictureUrl: require('@/assets/imgs/picture.png'),
      closeUrl: require('@/assets/imgs/icon-close.png'),
      checkUrl: require('@/assets/imgs/icon-check.png'),
      checkUrl2: require('@/assets/imgs/icon-checked.png'),
      addUrl: require('@/assets/imgs/icon-add.png'),
      chooseBitInfo: {},
      withdrawInfo: {},
      bitList: [
        {
          icon: require('../../assets/imgs/bit1.png'),
          name: 'USDT',
          key: 'USDT-TRC20'
        },
        {
          icon: require('../../assets/imgs/bit2.png'),
          name: 'USDC',
          key: 'USDC-TRC20'
        },
        {
          icon: require('../../assets/imgs/bit3.png'),
          name: 'ETH',
          key: 'ETH-TRC20'
        },
        {
          icon: require('../../assets/imgs/bit4.png'),
          name: 'BTC',
          key: 'Bitcoin'
        },
      ]
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.withdrawInfo = this.bitList[0]
    this.chooseBitInfo = this.bitList[0]
  },
  methods: {
    
    back() {
      this.$router.go(-1)
    },
    chooseFile(e) {

    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    submit() {
      if (!this.form.number) {
        this.$toast.fail(this.$t('withdraw.placeholder1'))
        return
      }
    },
    chooseBit(data) {
      this.chooseBitInfo = data
    },
    cancel() {
      this.showPopup = false
    },
    toTransfer() {
      this.showPopup = true
    },
    doTransfer() {
      this.withdrawInfo = Object.assign({}, this.chooseBitInfo)
      this.showPopup = false
    }
  }
}
</script>